import React, { useState, useEffect, useContext, useRef } from 'react';
import { m } from 'kremling';
import moment from 'moment-timezone';
import { UserStateContext } from 'context/user-state-context';
import { Button, Dropdown, DynamicTypeAhead, TimeSelector, Loader } from 'components';
import { List, ListItemButton, ListItemText, Checkbox, Stack, Box, Tabs, Tab, Table, TableBody, TableRow, TableCell, Typography, CircularProgress } from '@mui/material';
import Tooltip from 'rc-tooltip';
import { InformationIcon, WarningTriangleIcon } from 'components/mui';
import { createBroadcast, updateBroadcast, getSegments, getEmailTemplates, getSegmentCount, deleteBroadcast, getBroadcastsLogs } from '../../../shared/common.api';
import { Modal } from '../../../components/modal/modal.component';
import { Calendar } from 'components/calendar/calendar.component';
const historyStatuses = ['estimating', 'scheduled', 'processing', 'batching', 'sending', 'completed'];

/**
 * Get the broadcast send time.
 *
 * @param {str} sendWhen "now" or "later"
 * @param {any} broadcast form data
 * @param {moment} selectedDate date if we're sending "later"
 * @param {zone, label} broadcastTypeTimezoneHandler function to handle which setBroadcast to use based on the zone and label from filteredTimezones
 * @returns
 */
const getStartTime = (sendWhen, broadcast, selectedDate) => {
  let timeToUse;
  if (sendWhen === 'now') {
    timeToUse = moment().tz(broadcast.schedule_timezone).add(5, 'minutes');
  } else {
    const earliestTime = moment().tz(broadcast.schedule_timezone).add(5, 'minutes');
    const scheduledTime = selectedDate ? moment.tz(selectedDate.format('YYYY-MM-DD HH:mm:ss'), broadcast.schedule_timezone) : null;
    timeToUse = !scheduledTime || scheduledTime < earliestTime ? earliestTime : scheduledTime;
  }
  return timeToUse;
};
export const BroadcastEmailModal = props => {
  const {
    asCompany,
    flags
  } = useContext(UserStateContext);
  const [broadcast, setBroadcast] = useState({
    ...props.broadcast,
    drip: props.broadcast.id && props.broadcast.drip_end !== null,
    send_window: props.broadcast.id && props.broadcast.window_start !== null,
    schedule_timezone: props?.broadcast?.schedule_timezone || moment.tz.guess(),
    window_timezone: props.broadcast && props.broadcast.window_timezone !== null ? moment.tz.guess() : null
  });
  const [loading, setLoading] = useState(false);
  const [estimatedSends, setEstimatedSends] = useState(null);
  const [estimatedError, setEstimatedError] = useState(false);
  const [sendWhen, setSendWhen] = useState(props.broadcast && props.broadcast.id ? 'later' : 'now');
  const [selectedDate, setSelectedDate] = useState(props.broadcast && props.broadcast.id ? moment(props.broadcast.schedule_when).tz(props.broadcast.schedule_timezone) : null);
  const [targetDate, setTargetDate] = useState(props.broadcast && props.broadcast.id && props.broadcast.drip_end ? moment(props.broadcast.drip_end).tz(props.broadcast.drip_timezone) : null);
  const listRef = useRef(null);
  const [timezoneDropdownActive, setTimezoneDropdownActive] = useState(false);
  const [filteredTimezones, setFilteredTimezones] = useState([{
    value: 'America/New_York',
    label: 'Eastern Standard Time (EST)'
  }, {
    value: 'America/Chicago',
    label: 'Central Standard Time (CST)'
  }, {
    value: 'America/Denver',
    label: 'Mountain Standard Time (MST)'
  }, {
    value: 'America/Los_Angeles',
    label: 'Pacific Standard Time (PST)'
  }, {
    value: 'America/Anchorage',
    label: 'Alaska Standard Time (AKST)'
  }, {
    value: 'Pacific/Honolulu',
    label: 'Hawaii Standard Time (HST)'
  }, {
    value: 'Etc/GMT+4',
    label: 'Atlantic Standard Time (AST)'
  }, {
    value: 'Etc/GMT+7',
    label: 'Arizona'
  }]);
  const [timezoneSelected, setTimezoneSelected] = useState(filteredTimezones.find(d => d.value === props.broadcast.schedule_timezone)?.label);
  const [tab, setTab] = useState('details');
  const [historyData, setHistoryData] = useState(null);
  const [historyDataLoading, setHistoryDataLoading] = useState(false);
  useEffect(() => {
    if (!broadcast.drip || !targetDate || !estimatedSends) {
      setEstimatedError(false);
      return;
    }

    // Recalculate the estimated finish time.
    let startTime = getStartTime(sendWhen, broadcast, selectedDate);
    let duration = 0;
    let targetDateTz = moment(targetDate).tz(broadcast.drip_timezone);
    if (!broadcast.send_window) {
      duration = targetDateTz - startTime;
    } else {
      // calculate duration here
      const [startHour, startMinute, startSecond] = broadcast.window_start.split(':');
      const [endHour, endMinute, endSecond] = broadcast.window_end.split(':');
      do {
        const WINDOW_START = moment(startTime).tz(broadcast.window_timezone).set({
          hour: startHour,
          minute: startMinute,
          second: startSecond,
          millisecond: 0
        });
        const WINDOW_END = moment(startTime).tz(broadcast.window_timezone).set({
          hour: endHour,
          minute: endMinute,
          second: endSecond,
          millisecond: 0
        });
        duration += Math.min(targetDateTz, WINDOW_END) - Math.max(startTime, WINDOW_START);
        startTime = startTime.add(1, 'day').set({
          hour: startHour,
          minute: startMinute,
          second: startSecond,
          millisecond: 0
        });
      } while (startTime < targetDateTz);
    }
    const numBatches = Math.ceil(estimatedSends / 100) - 1;
    setEstimatedError(duration / 1000 / numBatches < 1);
  }, [estimatedSends, sendWhen, broadcast.schedule_timezone, selectedDate, broadcast.drip, targetDate, selectedDate, broadcast.drip_timezone, broadcast.send_window, broadcast.window_start, broadcast.window_end, broadcast.window_days, broadcast.window_timezone]);
  useEffect(() => {
    // Get the broadcast send count
    if (broadcast.segment) {
      setEstimatedSends(null);
      const promise = getSegmentCount(broadcast.segment, {
        distinct: !!broadcast.distinct_emails
      });
      promise.then(({
        data
      }) => {
        setEstimatedSends(data.count);
        if (data.count >= 10000) {
          setBroadcast(broadcast => ({
            ...broadcast,
            drip: true
          }));
        }
      }).catch();
      return () => {
        promise.controller.abort();
      };
    }
  }, [broadcast.segment, broadcast.distinct_emails]);
  const updateTime = (updatedTime, setFunc) => {
    setFunc(selectedDate => {
      const [hour, minute, second] = updatedTime.split(':');
      const newDate = moment(selectedDate) || moment();
      newDate.set({
        hour,
        minute,
        second
      });
      return newDate;
    });
  };
  const saveBroadcast = () => {
    setLoading(true);
    const timeToUse = getStartTime(sendWhen, broadcast, selectedDate);
    if (!broadcast.send_window) {
      broadcast.window_start = null;
      broadcast.window_end = null;
      broadcast.window_days = null;
      broadcast.window_timezone = null;
    }
    if (!broadcast.drip) {
      broadcast.drip_end = null;
      broadcast.drip_timezone = null;
    } else {
      broadcast.drip_end = moment.tz(targetDate.format('YYYY-MM-DD HH:mm:ss'), broadcast.drip_timezone);
    }
    delete broadcast.send_window;
    delete broadcast.drip;
    let promise;
    const newBroadcast = {
      ...broadcast,
      company: asCompany.id,
      schedule_when: timeToUse.toDate()
    };
    if (newBroadcast.id) {
      promise = updateBroadcast(newBroadcast.id, newBroadcast);
    } else {
      promise = createBroadcast(newBroadcast);
    }
    promise.then(() => {
      props.onSave();
    }).catch(() => setLoading(false));
  };
  const canSave = () => {
    const sendNow = sendWhen === 'now' || !!selectedDate && !!broadcast.schedule_timezone;
    const dripEmails = !broadcast.drip || !!targetDate && !!broadcast.drip_timezone;
    const sendWindow = !broadcast.send_window || !!broadcast.window_start && !!broadcast.window_end && !!broadcast.window_days && broadcast.window_days.length > 0 && broadcast.window_start < broadcast.window_end;
    return !loading && !!sendNow && !!broadcast.email_template && !!broadcast.segment && dripEmails && sendWindow && (timezoneSelected !== null || sendWhen !== 'later') && (sendWhen === 'now' ? !broadcast.drip && !broadcast.send_window || timezoneSelected !== null : true);
  };
  const toggleDripDay = day => {
    const window_days = broadcast.window_days || [];
    const index = window_days.indexOf(day);
    if (index === -1) {
      window_days.push(day);
    } else {
      window_days.splice(index, 1);
    }
    setBroadcast(broadcast => ({
      ...broadcast,
      window_days: [...window_days]
    }));
  };
  const deleteEmailBroadcast = broadcast => {
    deleteBroadcast(broadcast.id).then(() => {
      props.onDelete();
    });
  };
  const broadcastTypeTimezoneHandler = (zone, label) => {
    if (sendWhen === 'now') {
      setBroadcast(broadcast => ({
        ...broadcast,
        schedule_timezone: zone
      }));
    }
    if (sendWhen === 'later') {
      setBroadcast(broadcast => ({
        ...broadcast,
        schedule_timezone: zone
      }));
    }
    if (broadcast.drip) {
      setBroadcast(broadcast => ({
        ...broadcast,
        drip_timezone: zone
      }));
    }
    if (broadcast.send_window) {
      setBroadcast(broadcast => ({
        ...broadcast,
        window_timezone: zone
      }));
    }
    setTimezoneSelected(label);
  };
  const timezoneDropdownHandler = () => {
    if (broadcast.send_window && broadcast.drip || sendWhen === 'later') {
      setTimezoneDropdownActive(true);
    } else if (!broadcast.send_window && !broadcast.drip && sendWhen === 'now') {
      setTimezoneDropdownActive(false);
    } else {
      setTimezoneDropdownActive(true);
    }
  };
  useEffect(() => {
    timezoneDropdownHandler();
  }, [broadcast.send_window, broadcast.drip, sendWhen]);
  useEffect(() => {
    if (tab == 'history' && !historyData) {
      setHistoryDataLoading(true);
      getBroadcastsLogs({
        broadcast: broadcast.id,
        broadcast__company: asCompany.id
      }).then(res => {
        if (res.data.results.length === 0) {
          setHistoryDataLoading(false);
          return;
        }
        const reduced = res.data.results.reduce((res, item) => {
          if (!item?.status || !item?.updated_when) {
            return res;
          }
          res[item.status] = item.updated_when;
          return res;
        }, {});
        if (Object.keys(reduced).length === 0) {
          setHistoryDataLoading(false);
          return;
        }
        setHistoryData(reduced);
        setHistoryDataLoading(false);
      }).catch(err => {
        console.log(err);
        setHistoryDataLoading(false);
      });
    }
  }, [tab]);
  const makeHistoryRow = status => {
    return <TableRow key={status} sx={{
      borderBottom: 'none'
    }}>
        <TableCell sx={{
        borderBottom: 'none'
      }}>
          <Typography sx={{
          textTransform: 'capitalize',
          color: '#808080'
        }}>{status} </Typography>
        </TableCell>

        <TableCell sx={{
        borderBottom: 'none'
      }}>
          <Typography sx={{
          color: '#808080'
        }}>
            {historyData[status] ? moment(historyData[status]).local().format('MMM D, YYYY h:mm A') : '--'}
          </Typography>
        </TableCell>
      </TableRow>;
  };
  return <Modal size="lg" open={!!broadcast} onClose={props.onClose} allowBackdropClick title={broadcast.id ? props.disabled ? 'View Email Broadcast' : 'Edit Email Broadcast' : 'Create Email Broadcast'}>
      {flags?.email_broadcast_logs && broadcast?.id && props?.disabled && <Tabs value={tab} onChange={(e, val) => setTab(val)} style={{
      marginBottom: '10px'
    }}>
          <Tab sx={{
        textTransform: 'none'
      }} label="Details" value="details" />
          <Tab sx={{
        textTransform: 'none'
      }} label="Status History" value="history" />
        </Tabs>}

      {tab == 'details' && <div className="modal__body" style={{
      maxHeight: 'calc(100vh - 200px)',
      overflowY: 'auto'
    }}>
          <div className="form-group">
            <label>
              Segment <span className="form-error">*</span>
            </label>
            <DynamicTypeAhead disabled={props.disabled} getItems={getSegments} getItemsFilters={{
          company: asCompany.id
        }} placeholder={broadcast.segment_name ? broadcast.segment_name : 'Choose Customer Segment'} displayProperty="name" keyProperty="id" value={broadcast.segment ? broadcast.segment : null} onChange={val => {
          setBroadcast(broadcast => ({
            ...broadcast,
            segment: val ? val.id : null,
            segment_name: val ? val.name : null
          }));
        }} />
          </div>
          <div className="form-group">
            <label>
              Template <span className="form-error">*</span>
            </label>
            <DynamicTypeAhead disabled={props.disabled} getItems={getEmailTemplates} getItemsFilters={{
          company: asCompany.id,
          archived: false,
          published: true
        }} placeholder="Choose Published Email Template" displayProperty="name" keyProperty="id" value={broadcast.email_template} onChange={val => setBroadcast(broadcast => ({
          ...broadcast,
          email_template: val ? val.id : null,
          email_template_name: val ? val.name : null
        }))} />
          </div>
          {!props.disabled && <div className="form-group space-between">
              <label>When to send broadcast?</label>
              <div className="toggler">
                <Button onClick={() => {
            setSendWhen('now');
            if (!broadcast.drip && !broadcast.send_window) {
              setTimezoneSelected(null);
            }
          }} className={m('active', sendWhen === 'now')}>
                  Now
                </Button>
                <Button onClick={() => setSendWhen('later')} className={m('active', sendWhen === 'later')}>
                  Later
                </Button>
              </div>
            </div>}

          {sendWhen === 'now' && broadcast.drip || sendWhen === 'now' && broadcast.send_window || sendWhen === 'later' ? <div className="row">
              <div className="col-timezoneDropdown">
                <div className="form-group">
                  <label>
                    Select Timezone{' '}
                    <span className="form-error">{sendWhen === 'later' || broadcast?.drip || broadcast?.send_window ? '*' : ''}</span>
                  </label>
                  <Dropdown ref={listRef} disabled={sendWhen === 'now' && !timezoneDropdownActive} size="md" contentHeight={280} trigger={() => <Button dropdown block disabled={props.disabled}>
                        {timezoneSelected || 'Select Timezone'}
                      </Button>} content={() => <List>
                        {filteredTimezones.map(zone => <ListItemButton sx={{
                height: '100%',
                width: '100%'
              }} onClick={() => {
                broadcastTypeTimezoneHandler(zone.value, zone.label);
                listRef.current.close();
              }} key={zone.value}>
                            <ListItemText primary={zone.label} />
                          </ListItemButton>)}
                      </List>} />
                </div>
              </div>
            </div> : null}

          {sendWhen === 'later' && <div className="row">
              <div className="col-4">
                <div className="form-group">
                  <label>Select Date</label>
                  <Dropdown disabled={props.disabled} size="md" allowContentClicks trigger={() => <Button dropdown block disabled={props.disabled}>
                        {selectedDate ? selectedDate.format('LL') : 'Select Date'}
                      </Button>} content={({
              close
            }) => <div className="p-sm">
                        <Calendar value={selectedDate ? selectedDate.toDate() : null} onChange={date => {
                setSelectedDate(moment(date));
                close();
              }} />
                      </div>} />
                </div>
              </div>
              <div className="col-5">
                <div className="form-group">
                  <label>Select Time</label>

                  <TimeSelector disabled={props.disabled} value={selectedDate} onChange={updatedTime => {
              if (!selectedDate) setSelectedDate(moment(new Date()));
              updateTime(updatedTime, setSelectedDate);
            }} />
                </div>
              </div>
            </div>}
          <div className="form-group col-broadcastDateToSend">
            <Stack direction="row" spacing={2}>
              <Box>
                <Checkbox sx={{
              p: 0,
              margin: 0,
              color: '#1D252D',
              '&.Mui-checked': {
                color: '#53A6D6'
              },
              '& .MuiSvgIcon-root': {
                fontSize: 22
              }
            }} disabled={props.disabled} checked={!!broadcast.drip} onChange={event => {
              setBroadcast(broadcast => ({
                ...broadcast,
                drip: event.target.checked
              }));
              if (!event.target.checked && sendWhen === 'now') {
                setTimezoneSelected(null);
              }
            }} />
              </Box>
              <label paddingLeft="10px" style={{
            display: 'flex',
            alignItems: 'center'
          }}>
                Drip emails over time
              </label>
            </Stack>
          </div>
          {!!broadcast.drip && <div className="row">
              <div className="col-4">
                <div className="form-group">
                  <label>
                    Target End Date <span className="form-error">*</span>
                  </label>
                  <Dropdown disabled={props.disabled} size="md" allowContentClicks trigger={() => <Button dropdown block disabled={props.disabled}>
                        {targetDate ? targetDate.format('LL') : 'Select Date'}
                      </Button>} content={({
              close
            }) => <div className="p-sm">
                        <Calendar disabled={props.disabled} value={targetDate ? targetDate.toDate() : null} onChange={date => {
                setTargetDate(moment(date));
                close();
              }} />
                      </div>} />
                </div>
              </div>
              <div className="col-5">
                <div className="form-group">
                  <label>
                    Select Time <span className="form-error">*</span>
                  </label>

                  <TimeSelector disabled={!targetDate || props.disabled} value={targetDate} onChange={updatedTime => updateTime(updatedTime, setTargetDate)} />
                </div>
              </div>
            </div>}
          <div className="form-group">
            <Stack direction="row" spacing={2}>
              <Box>
                <Checkbox sx={{
              p: 0,
              margin: 0,
              color: '#1D252D',
              '&.Mui-checked': {
                color: '#53A6D6'
              },
              '& .MuiSvgIcon-root': {
                fontSize: 22
              }
            }} disabled={props.disabled} checked={!!broadcast.send_window} onChange={event => {
              setBroadcast(broadcast => ({
                ...broadcast,
                send_window: event.target.checked
              }));
              if (!event.target.checked && sendWhen === 'now') {
                setTimezoneSelected(null);
              }
            }} />
              </Box>
              <label paddingLeft="10px" style={{
            display: 'flex',
            alignItems: 'center'
          }}>
                Use send window
              </label>
            </Stack>
          </div>

          {broadcast.send_window && <>
              <div className="row">
                <div className="form-group col-6">
                  <label>
                    Start Time <span className="form-error">*</span>
                  </label>

                  <TimeSelector disabled={props.disabled} value={broadcast.window_start} onChange={window_start => setBroadcast(broadcast => ({
              ...broadcast,
              window_start
            }))} />
                </div>
                <div className="form-group col-6">
                  <label>
                    End Time <span className="form-error">*</span>
                  </label>

                  <TimeSelector disabled={props.disabled} value={broadcast.window_end} onChange={window_end => setBroadcast(broadcast => ({
              ...broadcast,
              window_end
            }))} />
                </div>
              </div>
              <div className="form-group col-broadcastDateToSend">
                <div>
                  <label>
                    Dates to Send <span className="form-error">*</span>
                  </label>
                </div>
                <div className=" d-flex space-between" style={{
            justifyContent: 'space-between'
          }}>
                  <div>
                    <label alignContent="center">Sun</label>
                    <br />
                    <Checkbox disabled={props.disabled} type="checkbox" value={!!broadcast.window_days && broadcast.window_days.indexOf(0) > -1} checked={!!broadcast.window_days && broadcast.window_days.indexOf(0) > -1} onChange={() => toggleDripDay(0)} sx={{
                p: 0,
                margin: 0,
                color: '#1D252D',
                '&.Mui-checked': {
                  color: '#53A6D6'
                },
                '& .MuiSvgIcon-root': {
                  fontSize: 22
                }
              }}></Checkbox>
                  </div>
                  <div>
                    <label>Mon</label>
                    <br />
                    <Checkbox disabled={props.disabled} type="checkbox" value={!!broadcast.window_days && broadcast.window_days.indexOf(1) > -1} checked={!!broadcast.window_days && broadcast.window_days.indexOf(1) > -1} onChange={() => toggleDripDay(1)} sx={{
                p: 0,
                margin: 0,
                color: '#1D252D',
                '&.Mui-checked': {
                  color: '#53A6D6'
                },
                '& .MuiSvgIcon-root': {
                  fontSize: 22
                }
              }}></Checkbox>
                  </div>
                  <div>
                    <label>Tue</label>
                    <br />
                    <Checkbox disabled={props.disabled} type="checkbox" value={!!broadcast.window_days && broadcast.window_days.indexOf(2) > -1} checked={!!broadcast.window_days && broadcast.window_days.indexOf(2) > -1} onChange={() => toggleDripDay(2)} sx={{
                p: 0,
                margin: 0,
                color: '#1D252D',
                '&.Mui-checked': {
                  color: '#53A6D6'
                },
                '& .MuiSvgIcon-root': {
                  fontSize: 22
                }
              }}></Checkbox>
                  </div>
                  <div>
                    <label>Wed</label>
                    <br />
                    <Checkbox disabled={props.disabled} type="checkbox" value={!!broadcast.window_days && broadcast.window_days.indexOf(3) > -1} checked={!!broadcast.window_days && broadcast.window_days.indexOf(3) > -1} onChange={() => toggleDripDay(3)} sx={{
                p: 0,
                margin: 0,
                color: '#1D252D',
                '&.Mui-checked': {
                  color: '#53A6D6'
                },
                '& .MuiSvgIcon-root': {
                  fontSize: 22
                }
              }}></Checkbox>
                  </div>
                  <div>
                    <label>Thu</label>
                    <br />
                    <Checkbox disabled={props.disabled} type="checkbox" value={!!broadcast.window_days && broadcast.window_days.indexOf(4) > -1} checked={!!broadcast.window_days && broadcast.window_days.indexOf(4) > -1} onChange={() => toggleDripDay(4)} sx={{
                p: 0,
                margin: 0,
                color: '#1D252D',
                '&.Mui-checked': {
                  color: '#53A6D6'
                },
                '& .MuiSvgIcon-root': {
                  fontSize: 22
                }
              }}></Checkbox>
                  </div>
                  <div>
                    <label>Fri</label>
                    <br />
                    <Checkbox disabled={props.disabled} type="checkbox" value={!!broadcast.window_days && broadcast.window_days.indexOf(5) > -1} checked={!!broadcast.window_days && broadcast.window_days.indexOf(5) > -1} onChange={() => toggleDripDay(5)} sx={{
                p: 0,
                margin: 0,
                color: '#1D252D',
                '&.Mui-checked': {
                  color: '#53A6D6'
                },
                '& .MuiSvgIcon-root': {
                  fontSize: 22
                }
              }}></Checkbox>
                  </div>
                  <div>
                    <label>Sat</label>
                    <br />
                    <Checkbox disabled={props.disabled} type="checkbox" value={!!broadcast.window_days && broadcast.window_days.indexOf(6) > -1} checked={!!broadcast.window_days && broadcast.window_days.indexOf(6) > -1} onChange={() => toggleDripDay(6)} sx={{
                p: 0,
                margin: 0,
                color: '#1D252D',
                '&.Mui-checked': {
                  color: '#53A6D6'
                },
                '& .MuiSvgIcon-root': {
                  fontSize: 22
                }
              }}></Checkbox>
                  </div>
                </div>
              </div>
            </>}
          <div className="form-group">
            <Stack direction={'row'} spacing={2}>
              <Box>
                <Checkbox sx={{
              p: 0,
              margin: 0,
              color: '#1D252D',
              '&.Mui-checked': {
                color: '#53A6D6'
              },
              '& .MuiSvgIcon-root': {
                fontSize: 22
              }
            }} disabled={props.disabled} checked={!!broadcast.distinct_emails} onChange={event => {
              setBroadcast(broadcast => ({
                ...broadcast,
                distinct_emails: event.target.checked
              }));
            }} />
              </Box>
              <label paddingLeft="10px" style={{
            display: 'flex',
            alignItems: 'center'
          }}>
                Send only one email per email address
              </label>
            </Stack>
          </div>

          <div className="form-group">
            <Stack direction={'row'} spacing={2}>
              <Box>
                <Checkbox sx={{
              p: 0,
              margin: 0,
              color: '#1D252D',
              '&.Mui-checked': {
                color: '#53A6D6'
              },
              '& .MuiSvgIcon-root': {
                fontSize: 22
              }
            }} disabled={props.disabled} checked={!!broadcast.send_to_suppressed} onChange={event => {
              setBroadcast(broadcast => ({
                ...broadcast,
                send_to_suppressed: event.target.checked
              }));
            }} />
              </Box>
              <label paddingLeft="10px" style={{
            display: 'flex',
            alignItems: 'center'
          }}>
                Send to suppressed emails.
                <Tooltip overlay={<>
                      This option will enable your email broadcast to be sent to suppressed email addresses. Repeatedly sending emails to
                      unengaged customers can damage your email reputation (especially with Google / Gmail).
                    </>}>
                  <Box>
                    <InformationIcon height="20px" width="20px" />
                  </Box>
                </Tooltip>
              </label>
            </Stack>
          </div>

          <div className="form-group">
            <label>Google Analytics Campaign Title</label>
            <input disabled={props.disabled} className="form-control" name="google_campaign" onChange={e => {
          const value = e.target.value.split(' ').join('_');
          setBroadcast(broadcast => ({
            ...broadcast,
            google_campaign: value
          }));
        }} placeholder="Optional" value={broadcast.google_campaign || ''} type="text" />
          </div>
          {broadcast.drip && broadcast.segment && estimatedSends === null && <p>
              <em>Loading estimates...</em>
            </p>}
          {!!estimatedError && <p className="text-danger">
              Warning. Due to current settings the broadcast won't finish before the target end date and will continue to send afterward.
            </p>}
          <div className="form-error text-sm">* Required</div>
        </div>}

      {tab == 'history' && <div className="modal__body" style={{
      overflowY: 'auto'
    }}>
          {historyDataLoading ? <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        pt: '55px'
      }}>
              <CircularProgress />
            </Box> : <>
              {historyData ? <Box sx={{
          py: 1
        }}>
                  <Table>
                    <colgroup>
                      <col width="30%" />
                      <col width="70%" />
                    </colgroup>
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{
                  borderBottom: 'none'
                }}>
                          <Typography sx={{
                    color: '#808080'
                  }}>STATUS </Typography>
                        </TableCell>

                        <TableCell sx={{
                  borderBottom: 'none'
                }}>
                          <Typography sx={{
                    color: '#808080'
                  }}>DATE </Typography>
                        </TableCell>
                      </TableRow>

                      {historyStatuses.map(status => makeHistoryRow(status))}
                    </TableBody>
                  </Table>
                </Box> : <Stack spacing={2} sx={{
          py: 1
        }}>
                  <WarningTriangleIcon />
                  <Typography>No historical data available</Typography>
                  <Typography> Email Broadcasts created prior to 4/7/2025 have no status history log.</Typography>
                </Stack>}
            </>}
        </div>}

      <div className="modal__actions">
        <div className="d-flex justify-content-between">
          {broadcast.id && !props.disabled && <Button actionType="danger" onClick={() => {
          deleteEmailBroadcast(broadcast);
        }}>
              Delete
            </Button> || <div> </div>}
          <div>
            {!props.disabled && <Button onClick={() => props.onClose()}>Cancel</Button>}

            <Button actionType="primary" onClick={() => !props.disabled ? saveBroadcast() : props.onClose()} className="float-right" disabled={!canSave()}>
              {loading ? <Loader size="sm" /> : broadcast.id ? props.disabled ? 'Close' : 'Edit' : 'Create'}
            </Button>
          </div>
        </div>
      </div>
    </Modal>;
};